import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cityService from "./cityService";

const initialState = {
  cities: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  error: null,
  editingId: null,
  deletingId: null,
};

// create
export const createCity = createAsyncThunk(
  "cities/create",
  async (cityData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cityService.createCity(cityData, token);
    } catch (error) {
      console.log(error);
      const message = "api error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get all service
export const getAllcities = createAsyncThunk(
  "cities/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cityService.getAllCities(token);
    } catch (error) {
      console.log(error);
      const message = "api error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// delete service
export const deleteCity = createAsyncThunk(
  "cities/delete",
  async (cityId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cityService.deleteCity(cityId, token);
    } catch (error) {
      const message = "api error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setEditingId: (state, action) => {
      state.editingId = action.payload;
    },

    setDeletingId: (state, action) => {
      state.deletingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cities.push(action.payload);
      })
      .addCase(createCity.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllcities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllcities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cities = action.payload;
      })
      .addCase(getAllcities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cities = state.cities.filter(
          (city) => city.id !== action.payload.id
        );
      })
      .addCase(deleteCity.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setEditingId, setDeletingId } = citySlice.actions;
export default citySlice.reducer;
