import { useDispatch, useSelector } from "react-redux";
import { deleteBanner} from "../../features/banners/bannerSlice";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

const DeleteBannerModal = ({onClose, onShow}) => {
  const { deletingId } = useSelector((state) => state.banner);
  const dispatch = useDispatch();

  const deleteHandler = () => {
    dispatch(deleteBanner(deletingId));
    onClose();
    toast.success("Banner deleted");
    // window.location.reload(true);
  };
  return (
    <Modal show={onShow} onHide={onClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Delete Banner</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure to delete this banner?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Close
      </Button>
      <Button variant="danger" onClick={deleteHandler}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
export default DeleteBannerModal