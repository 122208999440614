import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../components/common/Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HiOutlineTrash } from "react-icons/hi";
import { Button } from "react-bootstrap";
import { getAllContacts, reset, setDeletingId } from "../features/contacts/contactSlice";
import DeleteContactModal from "../components/contacts/DeleteContactModal";
const Contacts = () => {

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  
  const { contacts, isError, isLoading, message } = useSelector(
    (state) => state.contact
  );
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const deleteModalCloseHandler = () => setIsDeleteModalOpen(false);
  const deleteModalShowHandler = (id) => {
    dispatch(setDeletingId(id));
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    if (isError) {
      // console.log(message);
      toast.error(message);
    }

    if (!user) {
      navigate("/login");
    }

    if (user) {
      dispatch(getAllContacts());
    }

    return () => {
      dispatch(reset());
    };
  }, [user, dispatch, message, isError, navigate]);

  if (isError) {
    console.log(message);
    toast.error(message);
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Container>
      <div className="d-flex flex-column gap-4 px-2 py-3 px-md-3">
        <div className="pt-3">
          <h2>Messages</h2>
        </div>
        <div className="px-0 py-3 px-md-3">
          {contacts.length > 0 && <Table
            responsive
            className="table table-hover table-bordered overflow-x-scroll"
          >
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
                <th scope="col">Time</th>
                <th scope="col">Date</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => {
                return (
                  <tr
                    key={contact.id}
                  >
                    <td >
                      {contact?.name ? contact.name : "No Name"}
                    </td>
                    <td >
                      {contact?.email ? contact?.email : "No Email"}
                    </td>
                    <td>{contact?.phone}</td>
                    <td>{contact?.subject}</td>
                    <td>{contact?.message}</td>
                    {/* <td>{contact?.created_at.split(" ")[1].split(":").slice(0, 2).join(":")}</td> */}
                    <td>{contact?.created_at.split(" ")[1]}</td>
                    <td>{contact?.created_at.split(" ")[0]}</td>
                    <td>
                    <div className="d-flex align-items-center gap-2 justify-content-center">
                          <Button
                            className="btn btn-sm btn-danger d-flex align-items-center justify-content-center p-2"
                            // disabled={item.userId !== user?.user?._id}
                            onClick={() => deleteModalShowHandler(contact.id)}
                          >
                            <HiOutlineTrash />
                          </Button>
                          {isDeleteModalOpen && (
                            <DeleteContactModal
                              onShow={isDeleteModalOpen}
                              onClose={deleteModalCloseHandler}
                            />
                          )}
                        </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>}
          {contacts.length === 0 && 
            <p className="text-center fs-2">No Message Yet!</p>}
        </div>
      </div>
    </Container>
  );
};
export default Contacts;
