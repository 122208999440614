import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteFaq } from "../../features/faqs/faqsSlice";

const DeleteQuestionModal = ({ onShow, onClose }) => {
  const { deletingId } = useSelector((state) => state.faq);
  const dispatch = useDispatch();

  const deleteHandler = () => {
    dispatch(deleteFaq(deletingId));
    // console.log(deletingId);
    onClose();
    // window.location.reload(true);
    toast.success("Question deleted");
  };


  return (
    <Modal show={onShow} onHide={onClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Delete Question</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure to delete this Question?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="danger" onClick={deleteHandler}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
export default DeleteQuestionModal