import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/common/Spinner";
import { getAllBanners, reset, setDeletingId, setEditingId } from "../features/banners/bannerSlice";
import BannerCard from "../components/banners/BannerCard";
import DeleteBannerModal from "../components/banners/DeleteBannerModal";
import AddBannerModal from "../components/banners/AddBannerModal";
import EditBannerModal from "../components/banners/EditBannerModal";

const Banners = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { banners, isError, isLoading, message } = useSelector(
    (state) => state.banner
  );
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addModalCloseHandler = () => setIsAddModalOpen(false);
  const deleteModalCloseHandler = () => setIsDeleteModalOpen(false);
  const deleteModalShowHandler = (id) => {
    dispatch(setDeletingId(id));
    setIsDeleteModalOpen(true);
  };
  const addModalShowHandler = () => {
    setIsAddModalOpen(true);
  };

  const editModalCloseHandler = () => setIsEditModalOpen(false);
  const editModalShowHandler = (id) => {
    dispatch(setEditingId(id));
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    if (isError) {
      console.log(message);
      toast.error(message);
    }

    if (!user) {
      navigate("/login");
    }

    if (user) {
      dispatch(getAllBanners());
    }

    return () => {
      dispatch(reset());
    };
  }, [user, dispatch, message, isError, navigate]);

  if (isError) {
    // console.log(message);
    toast.error(message);
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Container>
      <div className="d-flex flex-column gap-4 px-2 py-3 px-md-3">
      <div className="pt-3 d-flex align-items-center justify-content-between flex-wrap">
          <h2>Banners</h2>
          {banners?.length > 0 && <button className="btn btn-primary" onClick={addModalShowHandler}>Add Banner</button>}
        </div>
        <div className="px-0 py-3 px-md-3">
          <div className="row justify-content-center">
            <ul className="list d-flex flex-column gap-3 my-3">
              {banners?.map((banner) => (
                <li key={banner.id}>
                  <BannerCard onEdit={editModalShowHandler} onDelete={deleteModalShowHandler} id={banner.id} title={banner.title} image={`${process.env.REACT_APP_API_BASE_URL}${banner?.image}`} />
                </li>
              ))}
            </ul>
            {isDeleteModalOpen && (
            <DeleteBannerModal
              onShow={isDeleteModalOpen}
              onClose={deleteModalCloseHandler}
            />
          )}
          </div>
          {
            banners?.length === 0 && 
            <>
              <div className="text-center fs-2">No Banner found</div>
              <div className="d-flex align-items-center justify-content-center mt-3">
                <button
                onClick={addModalShowHandler} className="btn btn-primary">Add Banner</button>
              </div>
            </>
          }
        </div>
      </div>
      {isAddModalOpen && (
        <AddBannerModal
          onShow={isAddModalOpen}
          onClose={addModalCloseHandler}
        />
      )}
      {isEditModalOpen && (
        <EditBannerModal
          onShow={isEditModalOpen}
          onClose={editModalCloseHandler}
        />
      )}
    </Container>
  );
}
export default Banners