import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bannerService from "./bannerService";

const initialState = {
  banners: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  error: null,
  editingId: null,
  deletingId: null,
};

// create
export const createBanner = createAsyncThunk(
  "banners/create",
  async (bannerData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await bannerService.createBanner(bannerData, token);
    } catch (error) {
      console.log(error);
      const message = "api error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get all service
export const getAllBanners = createAsyncThunk(
  "banners/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await bannerService.getAllBanners(token);
    } catch (error) {
      console.log(error);
      const message = "api error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// delete service
export const deleteBanner = createAsyncThunk(
  "banners/delete",
  async (bannerId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await bannerService.deleteBanner(bannerId, token);
    } catch (error) {
      const message = "api error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setEditingId: (state, action) => {
      state.editingId = action.payload;
    },

    setDeletingId: (state, action) => {
      state.deletingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBanner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.banners.push(action.payload);
      })
      .addCase(createBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllBanners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBanners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.banners = action.payload;
      })
      .addCase(getAllBanners.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteBanner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.banners = state.banners.filter(
          (banner) => banner.id !== action.payload.id
        );
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setEditingId, setDeletingId } = bannerSlice.actions;
export default bannerSlice.reducer;
