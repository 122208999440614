import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { useSelector } from "react-redux";

const ResetPassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const onChangeHandler = (e) => {
    if (e.target.name === "oldPassword") {
      setOldPassword(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
    if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }
  };

  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      if (!oldPassword) {
        setOldPasswordError("Old Password is required");
      }
      if (!password) {
        setPasswordError("New Password is required");
      }
      if (!confirmPassword) {
        setConfirmPasswordError("Confirm Password is required");
      }
    } else if (password !== confirmPassword) {
      setPasswordMatchError("Password does not match");
      toast.error("Password does not match");
    } else {
      const resetPasswordData = { 
        old_password: oldPassword,
        password, 
        password_confirmation:confirmPassword 
      };

      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      }
      try {
        const res = await axios.put("api/change_password", resetPasswordData, config);
        if(res.data.success === true){

          toast.success(res.data.message);
          window.location.reload(true);
        }
      } catch (error) {
        if (error.response.status === 500) {
          toast.error("Server error");
        }
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        }
        if (error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }
    }
    setIsLoading(false);
    setValidated(true);
  };
  return (
    <section>
      <Container
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ minHeight: "calc(100vh - 56px)" }}
      >
        <Card className="p-3 mw-100">
          <Form
            noValidate
            validated={validated}
            className="mw-100"
            onSubmit={resetPasswordHandler}
          >
            <div className="d-flex align-item-center justify-content-center py-3">
              <h1 className="text-primary">Reset Password</h1>
            </div>
            
            <Col sm={12}>
              <FloatingLabel
                controlId="floatingInputOldPassword"
                label="Old Password"
                className="mb-1"
              >
                <span
                  className={`showPassword ${
                    !validated ? "notValidated" : "validated"
                  }`}
                  onClick={() => setShowOldPassword((prev) => !prev)}
                >
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </span>
                <Form.Control
                  required
                  type={showOldPassword ? "text" : "password"}
                  placeholder="Old password"
                  name="oldPassword"
                  minLength={8}
                  value={oldPassword}
                  onChange={(e) => onChangeHandler(e)}
                />
                <Form.Control.Feedback className={"ms-3"} type="invalid">
                  {oldPasswordError}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col sm={12}>
              <FloatingLabel
                controlId="floatingInputPassword"
                label="Password"
                className="mb-1"
              >
                <span
                  className={`showPassword ${
                    !validated ? "notValidated" : "validated"
                  }`}
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </span>
                <Form.Control
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="password"
                  name="password"
                  minLength={8}
                  value={password}
                  onChange={(e) => onChangeHandler(e)}
                />
                <Form.Control.Feedback className={"ms-3"} type="invalid">
                  {passwordError}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col sm={12}>
              <FloatingLabel
                controlId="floatingInputconfirmpassword"
                label="Confirm Password"
                className="mb-1"
              >
                <span
                  className={`showPassword ${
                    !validated ? "notValidated" : "validated"
                  }`}
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                >
                  {showConfirmPassword ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )}
                </span>
                <Form.Control
                  required
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="password"
                  name="confirmPassword"
                  minLength={8}
                  value={confirmPassword}
                  onChange={(e) => onChangeHandler(e)}
                />
                <Form.Control.Feedback className={"ms-3"} type="invalid">
                  {confirmPasswordError}
                </Form.Control.Feedback>
                <Form.Control.Feedback className={"ms-3"} type="invalid">
                  {passwordMatchError}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>

            <Form.Group as={Row} className="my-4 text-center">
              <Col>
                <Button type="submit" disabled={isLoading}>{`${
                  isLoading ? "Submitting" : "Submit"
                }`}</Button>
              </Col>
            </Form.Group>
          </Form>
        </Card>
      </Container>
    </section>
  );
};
export default ResetPassword;
