import axios from "../../api/axios";

const API_URL = "api/bookings";


// get Bookings
const getAllBookings = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data.data;
};

const deleteBooking = async (bookingId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${bookingId}`, config);

  if(response.status === 204){
    return {id: bookingId}
  }
};


const bookingService = {
  getAllBookings,
  deleteBooking,
};

export default bookingService;
