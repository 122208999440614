import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllcities } from "../features/cities/citySlice";
import { createService } from "../features/services/serviceSlice";

const AddNewService = () => {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [groupId, setGroupId] = useState("");
  const [description, setDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [images, setImages] = useState([]);
  const [informations, setInformations] = useState([]);
  const [newInformation, setNewInformation] = useState('');
  const [inclusions, setInclusions] = useState([]);
  const [newInclusion, setNewInclusion] = useState('');
  const [highlights, setHighlights] = useState([]);
  const [newHighlight, setNewHighlight] = useState('');
  const [popular, setPopular] = useState("false");
  const [isError, setIsError] = useState(false);

  const [highlightButtonEnable, setHighlightButtonEnable] = useState(true);
  const [inclusionButtonEnable, setInclusionButtonEnable] = useState(true);
  const [informationButtonEnable, setInformationButtonEnable] = useState(true);

  const {isLoading} = useSelector(state => state.service)
  const {cities, message} = useSelector(state => state.city)
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addHighlightsHandler = () => {
    setHighlights([...highlights, newHighlight]);
    setNewHighlight('');
    setHighlightButtonEnable(true);
  }
  const addInclusionsHandler = () => {
    setInclusions([...inclusions, newInclusion]);
    setNewInclusion('');
    setInclusionButtonEnable(true)
  }
  const addInformationsHandler = () => {
    setInformations([...informations, newInformation]);
    setNewInformation('');
    setInformationButtonEnable(true);
  }

  const resetForm = () => {
    setTitle("");
    setSubTitle("")
    setDescription("");
    setLongDescription("");
    setImages([]);
    setInformations([])
    setInclusions([]);
    setHighlights([]);
    setNewInformation('');
    setNewInclusion('');
    setNewHighlight('');
    setPopular("false");
  }
  const addServiceHandler = async (e) => {
    e.preventDefault();
    if(isError){
      toast.error("All Fields are required")
    }

    const serviceData = {
      title,
      sub_title: subTitle ? subTitle : title,
      description,
      long_description: longDescription,
      daily_charges: 0,
      weekly_charges: 0,
      monthly_charges: 0,
      sequence: 1,
      popular,
      informations,
      inclusions,
      highlights,
      group_id: groupId,
      images,
    }

    console.log(serviceData);
    try {
      // console.log(images);
      dispatch(createService(serviceData))
        resetForm();
        toast.success("Service Added!");
        navigate('/services');
    } catch (error) {
      console.log(error);
      toast.error(error)
    }
  
  }
  useEffect(() => {
    if(!title  || !groupId || !description || !longDescription || !images || !informations || !inclusions || !highlights){
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [title, groupId, description, longDescription, images, informations, inclusions, highlights ]);

  useEffect(() => {

    if (!user) {
      navigate("/login");
    }

    if (user) {
      dispatch(getAllcities());
    }

  }, [user, dispatch, message, navigate]);

  return (
    <section className="my-5">
    <Container>
      <h2 className="mb-3">Add New Service</h2>
      <Form className="col-12 d-flex flex-column gap-2" onSubmit={addServiceHandler}>
        <Row className="align-items-end gap-2 gap-md-0">
          <Col sm="12" md="4">
            <label htmlFor="inputTitle">Title</label>
            <input
              type="text"
              className="form-control"
              id="inputTitle"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Col>
          <Col sm="12" md="4">
            <label htmlFor="inputSubTitle">SubTitle</label>
            <input
              type="text"
              className="form-control"
              id="inputSubTitle"
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
            />
          </Col>
          <Col sm="12" md="4">
            <label htmlFor="inputSubTitle">City</label>
            <Form.Select onChange={(e) => setGroupId(e.target.value)} aria-label="Default select example">
              <option>Select City</option>
              {
                cities.map(city => (
                  <option key={`city_${city.id}`} value={city.id}>{city.title}</option>
                ))
              }
              
            </Form.Select>
          </Col>
        </Row>
        <Row>
        <Col md={12}>
            <label htmlFor="inputDescription">Description</label>
            <input
              type="text"
              className="form-control"
              id="inputDescription"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Col>
        </Row>
        <Row>
        <Col md={12}>
              <label htmlFor="inputLongDescription">Long Description</label>
              <textarea 
                className="form-control" 
                id="inputLongDescription" 
                rows="3"
                value={longDescription}
                onChange={(e) => setLongDescription(e.target.value)}
                required
                ></textarea>
            </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-3">
            <label htmlFor="inputHighlights">Highlights</label>
            <div className="d-flex align-items-center gap-3">
              <input
                type="text"
                className="form-control"
                id="inputHighlights"
                value={newHighlight}
                onChange={(e) => {
                  if(e.target.value !== ""){
                    setHighlightButtonEnable(false);
                  }else{
                    setHighlightButtonEnable(true)
                  }
                  setNewHighlight(e.target.value)
                }}
              />
              <button onClick={addHighlightsHandler} disabled={highlightButtonEnable} className="btn btn-secondary">Add</button>
            </div>
          </Col>
          <Col sm="12">
            {highlights.length > 0 && <ul className="list d-flex flex-column gap-2">
              {highlights.map((highlight, index) => (
                <li key={`highlight_${index}`}>
                  <Card className="p-2 shadow-sm">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0">{highlight}</p>
                    </div>
                  </Card>
                </li>
              ))}
            </ul>}
            {highlights.length === 0 && <p>You can add More than one Highlight</p>}
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-3">
            <label htmlFor="inputInclusions">Inclusions</label>
            <div className="d-flex align-items-center gap-3">
              <input
                type="text"
                className="form-control"
                id="inputInclusions"
                value={newInclusion}
                onChange={(e) => {
                  if(e.target.value !== ""){
                    setInclusionButtonEnable(false);
                  }else{
                    setInclusionButtonEnable(true)
                  }
                  setNewInclusion(e.target.value)
                }}
              />
              <button onClick={addInclusionsHandler} disabled={inclusionButtonEnable} className="btn btn-secondary">Add</button>
            </div>
          </Col>
          <Col sm="12">
            {inclusions.length > 0 && <ul className="list d-flex flex-column gap-2">
              {inclusions.map((inclusion, index) => (
                <li key={`inclusion_${index}`}>
                <Card className="p-2 shadow-sm">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0">{inclusion}</p>
                  </div>
                </Card>
              </li>
              ))}
            </ul>}
            {inclusions.length === 0 && <p>You can add More than one Inclusion</p>}
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-3">
            <label htmlFor="inputInformations">Informations</label>
            <div className="d-flex align-items-center gap-3">
              <input
                type="text"
                className="form-control"
                id="inputInformations"
                value={newInformation}
                onChange={(e) => {
                  if(e.target.value !== ""){
                    setInformationButtonEnable(false);
                  }else{
                    setInformationButtonEnable(true)
                  }
                  setNewInformation(e.target.value)
                }}
              />
              <button onClick={addInformationsHandler} disabled={informationButtonEnable} className="btn btn-secondary">Add</button>
            </div>
          </Col>
          <Col sm="12">
            {informations.length > 0 && <ul className="list d-flex flex-column gap-2">
              {informations.map((information, index) => (
                <li key={`information_${index}`}>
                  <Card className="p-2 shadow-sm">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0">{information}</p>
                    </div>
                  </Card>
                </li>
              ))}
            </ul>}
            {informations.length === 0 && <p>You can add More than one information</p>}
          </Col>
        </Row>
        <Row className="mt-3">
        <Col>
            <label htmlFor="inputImages">Images</label>
            <input
              type="file"
              className="form-control"
              id="inputImages"
              onChange={(e) =>
                  setImages([...e.target.files])
              }
              required
              multiple
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
          <Form.Check
              label="Is this tour popular"
              name="popular"
              type={"checkbox"}
              id={`isPopular`}
              onChange={(e) => {
                if(e.target.checked){
                  setPopular("true")
                }else{
                  setPopular("false")
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
              <button className="btn btn-primary" type="submit" disabled={isError || isLoading}>
                {isLoading ? "Submitting": "Submit"}
              </button>
          </Col>
        </Row>
      </Form>
    </Container>
    </section>
  )
}
export default AddNewService