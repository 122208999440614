import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllBookings, reset, setDeletingId } from "../features/bookings/bookingSlice";
import Spinner from "../components/common/Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HiOutlineTrash } from "react-icons/hi";
import { Button } from "react-bootstrap";
import DeleteBookingModal from "../components/bookings/DeleteBookingModal";
const Bookings = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { bookings, isError, isLoading, message } = useSelector(
    (state) => state.booking
  );
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const deleteModalCloseHandler = () => setIsDeleteModalOpen(false);
  const deleteModalShowHandler = (id) => {
    dispatch(setDeletingId(id));
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    if (isError) {
      // console.log(message);
      toast.error(message);
    }

    if (!user) {
      navigate("/login");
    }

    if (user) {
      dispatch(getAllBookings());
    }

    return () => {
      dispatch(reset());
    };
  }, [user, dispatch, message, isError, navigate]);

  if (isError) {
    // console.log(message);
    toast.error(message);
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Container>
      <div className="d-flex flex-column gap-4 px-2 py-3 px-md-3">
        <div className="pt-3">
          <h2>Bookings</h2>
        </div>
        <div className="px-0 py-3 px-md-3">
          {bookings.length > 0 && <Table
            responsive
            className="table table-hover table-bordered overflow-x-scroll"
          >
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">
                  Email
                </th>
                <th scope="col">Phone</th>
                <th scope="col">Service</th>
                <th scope="col">Transfer Type</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
                <th scope="col">Time</th>
                <th scope="col">Date</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => {
                return (
                  <tr
                    key={booking.id}
                  >
                    <td >
                      {booking?.name ? booking.name : "No Name"}
                    </td>
                    <td >
                      {booking?.email ? booking?.email : "No Email"}
                    </td>
                    <td>{booking?.phone}</td>
                    <td>{booking?.service?.title}</td>
                    <td>{booking?.transfer_type}</td>
                    <td>{booking?.subject}</td>
                    <td>{booking?.message}</td>
                    <td>{booking?.created_at.split(" ")[1]}</td>
                    <td>{booking?.created_at.split(" ")[0]}</td>
                    <td>
                    <div className="d-flex align-items-center gap-2 justify-content-center">
                          <Button
                            className="btn btn-sm btn-danger d-flex align-items-center justify-content-center p-2"
                            // disabled={item.userId !== user?.user?._id}
                            onClick={() => deleteModalShowHandler(booking.id)}
                          >
                            <HiOutlineTrash />
                          </Button>
                          {isDeleteModalOpen && (
                            <DeleteBookingModal
                              onShow={isDeleteModalOpen}
                              onClose={deleteModalCloseHandler}
                            />
                          )}
                        </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>}
          {bookings.length === 0 && 
            <p className="text-center fs-2">No Booking Yet!</p>}
        </div>
      </div>
    </Container>
  );
};
export default Bookings;
