import classes from './bannerCard.module.css'

const BannerCard = ({ title, image, id, onDelete, onEdit}) => {
  
  return (
    <div className={classes.card}>
      <img src={image} className={classes.cardImage} alt="vehicle"/>
      <div className={classes.cardContent}>
        <h3 className={classes.cardTitle}>{title}</h3>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <button onClick={() => onEdit(id)} className="btn btn-primary">Edit</button>
          <button onClick={() => onDelete(id)} className="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>
  );
}
export default BannerCard