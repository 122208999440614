import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createFaq } from "../../features/faqs/faqsSlice";

const AddNewQuestionModal = ({ onShow, onClose }) => {

  const [question, setQuestion] = useState("")
  const [answer, setAnswer] = useState("")
  const [priority, setPriority] = useState("")
  const [isError, setIsError] = useState(false);
  const { isLoading, isSuccess } = useSelector((state) => state.faq);
  const dispatch = useDispatch();


  useEffect(() => {
    if (!question || !answer || !priority) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [question, answer, priority]);

  const addNewQuestionHandler = async () => {
    const questionData = { question, answer, priority };
    try {
      dispatch(createFaq(questionData))
      if(isSuccess){
        toast.success("Question Added!");
      }
    } catch (error) {
      console.log(error);
    }
    onClose();
  };
  return (
    <Modal show={onShow} onHide={onClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Add Question</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form className="col-12">
        <Container>
          <Row className="align-items-end gap-3">
            <Col md={12}>
              <label htmlFor="inputQuestion">Question?</label>
              <input
                type="text"
                className="form-control"
                id="inputQuestion"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
              />
            </Col>
            <Col md={12}>
              <label htmlFor="inputAnswer">Answer</label>
              <input
                type="text"
                className="form-control"
                id="inputAnswer"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                required
              />
            </Col>
            <Col md={12}>
              <label htmlFor="inputPriority">Priority</label>
              <input
                type="text"
                className="form-control"
                id="inputPriority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                required
              />
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={addNewQuestionHandler} disabled={isError || isLoading}>
        {isLoading ? "Adding": "Add"}
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
export default AddNewQuestionModal