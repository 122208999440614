import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import faqsService from "./faqsService";

const initialState = {
  faqs: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  error: null,
  editingId: null,
  deletingId: null,
};

// create
export const createFaq = createAsyncThunk(
  "faqs/create",
  async (faqData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqsService.createFaq(faqData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get all faqs
export const getFaqs = createAsyncThunk(
  "faqs/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      // console.log(token)
      return await faqsService.getFaqs(token);
    } catch (error) {
      console.log(error);
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     error.response.data.message) ||
      //   error.message ||
      //   error.toString();
      const message = "api error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete emergency
export const deleteFaq = createAsyncThunk(
  "faqs/delete",
  async (faqId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqsService.deleteFaq(faqId, token);
    } catch (error) {
      const message = "deleting error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const faqsSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setEditingId: (state, action) => {
      state.editingId = action.payload;
    },

    setDeletingId: (state, action) => {
      state.deletingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFaq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.faqs.push(action.payload);
      })
      .addCase(createFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFaqs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFaqs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.faqs = action.payload;
      })
      .addCase(getFaqs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteFaq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.faqs = state.faqs.filter(
          (faq) => faq.id !== action.payload.id
        );
      })
      .addCase(deleteFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setEditingId, setDeletingId } = faqsSlice.actions;
export default faqsSlice.reducer;
