import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllcities } from "../features/cities/citySlice";
import { deleteServiceImage, getService } from "../features/services/serviceSlice";
import Spinner from "../components/common/Spinner";
import {FaTrashAlt} from 'react-icons/fa'
import axios from "../api/axios";

const UpdateService = () => {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [groupId, setGroupId] = useState(0);
  const [description, setDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [newImageSrc, setNewImageSrc] = useState([]);
  const [informations, setInformations] = useState([]);
  const [newInformation, setNewInformation] = useState('');
  const [inclusions, setInclusions] = useState([]);
  const [newInclusion, setNewInclusion] = useState('');
  const [highlights, setHighlights] = useState([]);
  const [newHighlight, setNewHighlight] = useState('');
  const [popular, setPopular] = useState('false');
  const [sequence, setSequence] = useState(null);
  const [isError, setIsError] = useState(false);

  const [highlightButtonEnable, setHighlightButtonEnable] = useState(true);
  const [inclusionButtonEnable, setInclusionButtonEnable] = useState(true);
  const [informationButtonEnable, setInformationButtonEnable] = useState(true);

  const {selectedService, isLoading} = useSelector(state => state.service)
  const {cities, message} = useSelector(state => state.city)
  const { user } = useSelector((state) => state.auth);
  const {id} = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addHighlightsHandler = () => {
    setHighlights([...highlights, newHighlight]);
    setNewHighlight('');
    setHighlightButtonEnable(true);
  }

  const deleteHighlightsHandler = (indexToDelete) => {
    const updatedItems = highlights.filter((_, index) => index !== indexToDelete);
    setHighlights(updatedItems);
  };
  
  const addInclusionsHandler = () => {
    setInclusions([...inclusions, newInclusion]);
    setNewInclusion('');
    setInclusionButtonEnable(true)
  }
  
  const deleteInclusionsHandler = (indexToDelete) => {
    const updatedItems = inclusions.filter((_, index) => index !== indexToDelete);
    setInclusions(updatedItems);
  };

  const addInformationsHandler = () => {
    setInformations([...informations, newInformation]);
    setNewInformation('');
    setInformationButtonEnable(true);
  }
  
  const deleteInformationsHandler = (indexToDelete) => {
    const updatedItems = informations.filter((_, index) => index !== indexToDelete);
    setInformations(updatedItems);
  };

  const imageUploadHandler = (e) => {
    const selectedImages = e.target.files;
    const imageSrcs = [];
    setNewImages([...e.target.files])

    for (let i = 0; i < selectedImages.length; i++) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageSrc = e.target.result;
        imageSrcs.push(imageSrc);
        setNewImageSrc([...imageSrcs]);
      };

      reader.readAsDataURL(selectedImages[i]);
    }
  }

  const deleteImageHandler = async(imageId) => {
    dispatch(deleteServiceImage(imageId));
    toast.success("Image deleted");
  }

  const deleteNewImagehandler = (imageIndex) => {
    const updatedImageSrc = newImageSrc.filter((_, index) => index !== imageIndex);
    setNewImageSrc([...updatedImageSrc]);

    const updatedImage = newImages.filter((_, index) => index !== imageIndex);
    setNewImages([...updatedImage]);
  }


  const updateServiceHandler = async (e) => {
    e.preventDefault();
    if(isError){
      toast.error("All Fields are required")
    }
    const formData = new FormData();

    // Append title and description
    formData.append('title', title);
    formData.append('sub_title', subTitle);
    formData.append('description', description);
    formData.append('long_description', longDescription);
    formData.append('daily_charges', 0);
    formData.append('weekly_charges', 0);
    formData.append('monthly_charges', 0);
    formData.append('popular', popular);
    informations.forEach((item, index) => {
      formData.append(`informations[${index}]`, item);
    });
    inclusions.forEach((item, index) => {
      formData.append(`inclusions[${index}]`, item);
    });
    highlights.forEach((item, index) => {
      formData.append(`highlights[${index}]`, item);
    });
    formData.append('group_id', groupId);
    formData.append("_method", "PUT");
    formData.append("sequence", sequence)

    // Append the selected file, if available
    if (newImages.length > 0) {
      newImages?.forEach((item, index) => {
        formData.append(`images[${index}]`, item, item.name);
      });
    }
    
    // console.log(formData);
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
      },
    };
    try {
      const response = await axios.post(
        `/api/services/${id}`,
        formData,
        config
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Service updated");
        window.location.reload(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status !== 200) {
        toast.error(error.response.data.message);
      }
    }
  
  }
  useEffect(() => {
    if(!title  || !description || !longDescription  || !informations || !inclusions || !highlights){
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [title, description, longDescription, informations, inclusions, highlights ]);

  
  useEffect(() => {
    setTitle(selectedService?.title);
    setSubTitle(selectedService?.sub_title);
    setGroupId(selectedService?.group?.id);
    setDescription(selectedService?.description);
    setLongDescription(selectedService?.long_description);
    setInclusions(selectedService.inclusions?.map(inclusion => inclusion.title));
    setHighlights(selectedService.highlights?.map(highlight => highlight.title));
    setInformations(selectedService.informations?.map(information => information.title))
    setImages(selectedService?.images);
    setPopular(selectedService?.popular === 1 ? "true" : "false");
    setSequence(selectedService?.sequence)
  }, [selectedService])

  useEffect(() => {
    dispatch(getService(id));
  }, [id, dispatch]);

  useEffect(() => {

    if (!user) {
      navigate("/login");
    }

    if (user) {
      dispatch(getAllcities());
    }

  }, [user, dispatch, message, navigate]);

  if(isLoading){
    return <Spinner />
  }

  return (
    <section className="my-5">
    <Container>
      <h2 className="mb-3">Add New Service</h2>
      <Form className="col-12 d-flex flex-column gap-2">
        <Row className="align-items-end gap-2 gap-md-0">
          <Col sm="12" md="4">
            <label htmlFor="inputTitle">Title</label>
            <input
              type="text"
              className="form-control shadow-sm"
              id="inputTitle"
              value={title ? title : ""}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Col>
          <Col sm="12" md="4">
            <label htmlFor="inputSubTitle">SubTitle</label>
            <input
              type="text"
              className="form-control shadow-sm"
              id="inputSubTitle"
              value={subTitle ? subTitle : ""}
              onChange={(e) => setSubTitle(e.target.value)}
              required
            />
          </Col>
          <Col sm="12" md="4">
            <label htmlFor="inputSubTitle">City</label>
            <Form.Select className="shadow-sm" value={groupId} disabled aria-label="Default select example">
              <option value="0">Select City</option>
              {
                cities?.map(city => (
                  <option key={`city_${city.id}`} value={city.id}>{city.title}</option>
                ))
              }
              
            </Form.Select>
          </Col>
        </Row>
        <Row>
        <Col md={12}>
            <label htmlFor="inputDescription">Description</label>
            <input
              type="text"
              className="form-control shadow-sm"
              id="inputDescription"
              value={description ? description : ''}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Col>
        </Row>
        <Row>
        <Col md={12}>
              <label htmlFor="inputLongDescription">Long Description</label>
              <textarea 
                className="form-control shadow-sm" 
                id="inputLongDescription" 
                rows="3"
                value={longDescription ? longDescription : ''}
                onChange={(e) => setLongDescription(e.target.value)}
                required
                ></textarea>
            </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-3">
            <label htmlFor="inputHighlights">Highlights</label>
            <div className="d-flex align-items-center gap-3">
              <input
                type="text"
                className="form-control shadow-sm"
                id="inputHighlights"
                value={newHighlight}
                onChange={(e) => {
                  if(e.target.value !== ""){
                    setHighlightButtonEnable(false);
                  }else{
                    setHighlightButtonEnable(true)
                  }
                  setNewHighlight(e.target.value)
                }}
              />
              <button onClick={addHighlightsHandler} disabled={highlightButtonEnable} className="btn btn-secondary">Add</button>
            </div>
          </Col>
          <Col sm="12">
            {highlights?.length > 0 && <ul className="list d-flex flex-column gap-2">
              {highlights.map((highlight, index) => (
                <li className="" key={`highlight_${index}`}>
                  <Card className="p-2 shadow-sm">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0">{highlight}</p>
                      <button className="btn btn-outline-danger" onClick={() => deleteHighlightsHandler(index)}>
                        <FaTrashAlt />
                      </button>
                    </div>
                  </Card>
                </li>
              ))}
            </ul>}
            {highlights?.length === 0 && <p>You can add More than one Highlight</p>}
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-3">
            <label htmlFor="inputInclusions">Inclusions</label>
            <div className="d-flex align-items-center gap-3">
              <input
                type="text"
                className="form-control shadow-sm"
                id="inputInclusions"
                value={newInclusion}
                onChange={(e) => {
                  if(e.target.value !== ""){
                    setInclusionButtonEnable(false);
                  }else{
                    setInclusionButtonEnable(true)
                  }
                  setNewInclusion(e.target.value)
                }}
              />
              <button onClick={addInclusionsHandler} disabled={inclusionButtonEnable} className="btn btn-secondary">Add</button>
            </div>
          </Col>
          <Col sm="12">
            {inclusions?.length > 0 && <ul className="list d-flex flex-column gap-2">
              {inclusions.map((inclusion, index) => (
                <li key={`inclusion_${index}`}>
                  <Card className="p-2 shadow-sm">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0">{inclusion}</p>
                      <button className="btn btn-outline-danger" onClick={() => deleteInclusionsHandler(index)}>
                        <FaTrashAlt />
                      </button>
                    </div>
                  </Card></li>
              ))}
            </ul>}
            {inclusions?.length === 0 && <p>You can add More than one Inclusion</p>}
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-3">
            <label htmlFor="inputInformations">Informations</label>
            <div className="d-flex align-items-center gap-3">
              <input
                type="text"
                className="form-control shadow-sm"
                id="inputInformations"
                value={newInformation}
                onChange={(e) => {
                  if(e.target.value !== ""){
                    setInformationButtonEnable(false);
                  }else{
                    setInformationButtonEnable(true)
                  }
                  setNewInformation(e.target.value)
                }}
              />
              <button onClick={addInformationsHandler} disabled={informationButtonEnable} className="btn btn-secondary">Add</button>
            </div>
          </Col>
          <Col sm="12">
            {informations?.length > 0 && <ul className="list d-flex flex-column gap-2">
              {informations?.map((information, index) => (
                <li key={`information_${index}`}>
                  <Card className="p-2 shadow-sm">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0">{information}</p>
                      <button className="btn btn-outline-danger" onClick={() => deleteInformationsHandler(index)}>
                        <FaTrashAlt />
                      </button>
                    </div>
                  </Card></li>
              ))}
            </ul>}
            {informations?.length === 0 && <p>You can add More than one information</p>}
          </Col>
        </Row>
        <ul className="mt-3 list grid col-auto my-3">
            {images?.map((image)=>(
              <li key={`image_${image.id}`}>
                <div className="updating-image-container">
                  <img className="updating_service-image" src={`${process.env.REACT_APP_API_BASE_URL}${image.image}`} alt={`service's`} />
                  {images.length > 1 && <button onClick={() => deleteImageHandler(image.id)} className="btn btn-danger btn-delete"><FaTrashAlt /></button>}
                </div>
              </li>
            ))}
            {
              newImageSrc?.map((newImage, index) => (
                <li key={`newImage_${index}`}>
                  <div className="updating-image-container">
                    <img className="updating_service-image" src={newImage} alt={`service's`} />
                    <button onClick={() =>() => deleteNewImagehandler(index)} className="btn btn-danger btn-delete"><FaTrashAlt /></button>
                  </div>
                </li>
              ))
            }
          </ul>
        <Row className="mt-3">
        <Col>
            <label htmlFor="inputImages">Images</label>
            <input
              type="file"
              className="form-control shadow-sm"
              id="inputImages"
              onChange={(e) =>
                imageUploadHandler(e)
              }
              multiple
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
          <Form.Check
              label="Is this tour popular"
              name="popular"
              type={"checkbox"}
              id={`isPopular`}
              checked={popular === "true"}
              onChange={(e) => {
                if(e.target.checked){
                  setPopular("true")
                }else{
                  setPopular("false")
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
              <button className="btn btn-primary" onClick={updateServiceHandler} disabled={isError || isLoading}>
                {isLoading ? "Updating": "Update"}
              </button>
          </Col>
        </Row>
      </Form>
    </Container>
    </section>
  )
}
export default UpdateService