import axios from "../../api/axios";

const API_URL = "api/faqs";

// create faq
const createFaq = async (faqData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, faqData, config);
  // console.log(response);

  return response.data.data;
};

// get faqs
const getFaqs = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "*/*"
    },
  };

  const response = await axios.get(API_URL, config);

  // console.log(response);

  return response.data.data;
};

const deleteFaq = async (faqId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${faqId}`, config);
  if(response.status === 204){
    return {id: faqId}
  }

  // return response.data;
};

const updateFaq = async (faqId, faqData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    API_URL + faqId,
    faqData,
    config
  );
  // console.log(response);
  return response.data;
};

const faqsService = {
  createFaq,
  getFaqs,
  deleteFaq,
  updateFaq,
};

export default faqsService;
