import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Missing from "./components/Missing";
import Bookings from "./pages/Bookings";
import ResetPassword from "./pages/ResetPassword";
import RequireAuth from "./components/RequiredAuth";
import Contacts from "./pages/Contacts";
import Faqs from "./pages/Faqs";
import Services from "./pages/Services";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cities from "./pages/Cities";
import AddNewService from "./pages/AddNewService";
import UpdateService from "./pages/UpdateService";
import Banners from "./pages/Banners";

function App() {
  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/resetPassword" element={<ResetPassword />} />

        {/* protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route element={<RequireAuth />}>
            <Route path="bookings" element={<Bookings />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/add-new" element={<AddNewService />} />
            <Route path="/services/:id" element={<UpdateService />} />
            <Route path="cities" element={<Cities />} />
            <Route path="banners" element={<Banners />} />
          </Route>
        </Route>
        <Route path="*" element={<Missing />} />
        {/* </Route> */}
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
