import axios from "../../api/axios";

const API_URL = "api/services";

// create faq
const createService = async (serviceData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": 'multipart/form-data',
    },
  };

  const response = await axios.post(API_URL, serviceData, config);
  console.log(response);

  return response.data.data;
};

// get All service
const getAllServices = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data.data;
};

const getService = async (serviceId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/${serviceId}`, config);

  return response.data.data;
};

const deleteService = async (serviceId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${serviceId}`, config);
  // console.log(response)
  if(response.status === 204){
    return {id: serviceId}
  }
  // return response.data;
};
const deleteServiceImage = async (imageId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`/api/image/${imageId}`, config);
  // console.log(response)
  if(response.status === 204){
    return {id: imageId}
  }
  // return response.data;
};

const updateService = async (serviceId, serviceData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/${serviceId}`,
    serviceData,
    config
  );
  // console.log(response);
  return response.data;
};

const serviceService = {
  createService,
  getService,
  getAllServices,
  deleteService,
  deleteServiceImage,
  updateService,
};

export default serviceService;
