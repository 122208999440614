import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../components/common/Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HiOutlineTrash } from "react-icons/hi";
import { Button } from "react-bootstrap";
import { getFaqs, reset, setDeletingId } from "../features/faqs/faqsSlice";
import AddNewQuestionModal from "../components/faqs/AddNewQuestionModal";
import DeleteQuestionModal from "../components/faqs/DeleteQuestionModal";

const Faqs = () => {
const [isAddModalOpen, setIsAddModalOpen] = useState(false);
const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { faqs, isError, isLoading, message } = useSelector(
    (state) => state.faq
  );
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addModalCloseHandler = () => setIsAddModalOpen(false);
  const deleteModalCloseHandler = () => setIsDeleteModalOpen(false);
  const deleteModalShowHandler = (id) => {
    dispatch(setDeletingId(id));
    setIsDeleteModalOpen(true);
  };
  const addModalShowHandler = () => {
    setIsAddModalOpen(true);
  };
  useEffect(() => {
    if (isError) {
      // console.log(message);
      toast.error(message);
    }

    if (!user) {
      navigate("/login");
    }

    if (user) {
      dispatch(getFaqs());
    }

    return () => {
      dispatch(reset());
    };
  }, [user, dispatch, message, isError, navigate]);

  if (isError) {
    // console.log(message);
    toast.error(message);
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Container>
      <div className="d-flex flex-column gap-4 px-2 py-3 px-md-3">
        <div className="pt-3 d-flex align-items-center justify-content-between flex-wrap">
          <h2>FAQs</h2>
          {faqs.length > 0 && <button className="btn btn-primary" onClick={addModalShowHandler}>Add Question</button>}
        </div>
        <div className="px-0 py-3 px-md-3">
          {faqs.length > 0 && <Table
            responsive
            className="table table-hover table-bordered overflow-x-scroll"
          >
            <thead>
              <tr>
                <th scope="col">Question</th>
                <th scope="col">Answer</th>
                <th scope="col">Priority</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
              faqs.map((faq) => {
                return (
                  <tr
                    key={faq.id}
                  >
                    <td >
                      {faq?.question}
                    </td>
                    <td >
                      {faq?.answer}
                    </td>
                    <td>{faq?.priority}</td>
                    <td className="d-flex align-items-center gap-2 justify-content-center">
                      <Button
                        className="btn btn-sm btn-danger d-flex align-items-center justify-content-center p-2"
                        onClick={() => deleteModalShowHandler(faq.id)}
                      >
                        <HiOutlineTrash />
                      </Button>
                      {isDeleteModalOpen && (
                        <DeleteQuestionModal
                          onShow={isDeleteModalOpen}
                          onClose={deleteModalCloseHandler}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>}
          {
            faqs.length === 0 && 
            <>
              <div className="text-center fs-2">No Question found</div>
              <div className="d-flex align-items-center justify-content-center mt-3">
                <button
                onClick={addModalShowHandler} className="btn btn-primary">Add Question</button>
              </div>
              
            </>
          
          }
        </div>
      </div>
      {isAddModalOpen && (
        <AddNewQuestionModal
          onShow={isAddModalOpen}
          onClose={addModalCloseHandler}
        />
      )}
    </Container>
  );
};
export default Faqs;
