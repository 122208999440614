import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { deleteBooking } from "../../features/bookings/bookingSlice";
import { toast } from "react-toastify";

const DeleteBookingModal = ({ onShow, onClose }) => {
  const { deletingId } = useSelector((state) => state.booking);
  const dispatch = useDispatch();

  const deleteHandler = () => {
    dispatch(deleteBooking(deletingId));
    onClose();
    toast.success("Booking deleted");
  };

  return (
    <Modal show={onShow} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Booking</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure to delete this booking?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="danger" onClick={deleteHandler}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteBookingModal;
