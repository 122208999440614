import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteService } from "../../features/services/serviceSlice";

const DeleteServiceModal = ({onClose, onShow}) => {
  const { deletingId } = useSelector((state) => state.service);
  const dispatch = useDispatch();

  const deleteHandler = () => {
    dispatch(deleteService(deletingId));
    // navigate("/services");
    onClose();
    toast.success("Service deleted");
    // window.location.reload(true);
  };
  return (
    <Modal show={onShow} onHide={onClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Delete Service</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure to delete this service?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Close
      </Button>
      <Button variant="danger" onClick={deleteHandler}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
export default DeleteServiceModal