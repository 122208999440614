import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import bookingReducer from "../features/bookings/bookingSlice";
import contactReducer from "../features/contacts/contactSlice";
import faqReducer from '../features/faqs/faqsSlice';
import serviceReducer from '../features/services/serviceSlice';
import cityReducer from '../features/cities/citySlice';
import bannerReducer from '../features/banners/bannerSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    booking: bookingReducer,
    contact: contactReducer,
    faq: faqReducer,
    service: serviceReducer,
    city: cityReducer,
    banner: bannerReducer,
  },
});
