import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/logo-Icon.png";

const Home = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <section className="text-center mt-5">
      <Container>
        <h1 className="">Welcome to MA <span className="text-primary">Travel</span> and <span className="text-primary">Tourism</span> Admin Panel</h1>
        <div className="mt-3">
          <p>
            Your all-in-one solution for hassle-free Travel Service management for clients.
          </p>
          <div className="home_logo">
            <img  src={logo} alt="" />
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Home;
