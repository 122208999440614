import logo from "../../assets/logo-Icon.png"

const Logo = () => {
  return (
    <div>
      <img className="logo" src={logo} alt="ma travel and tourism logo" />
    </div>
  );
};
export default Logo;
