import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCity } from "../../features/cities/citySlice";
import { toast } from "react-toastify";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

const AddCityModal = ({ onShow, onClose }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState();

  const [isError, setIsError] = useState(false);
  const {isLoading} = useSelector(state => state.service)

  const dispatch = useDispatch();

  useEffect(() => {
    if(!title || !description || !image){
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [title, description, image]);


  const addCityHandler = async() => {   
    const cityData = {
      title,
      description,
      image
    }
    try {
      // console.log(image);
      dispatch(createCity(cityData))
      toast.success("City Added!")
    } catch (error) {
      console.log(error);
    }
    onClose();
  }


  return (
    <Modal show={onShow} onHide={onClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Add City</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form className="col-12">
        <Container>
          <Row className="align-items-end gap-3">
            <Col md={12}>
              <label htmlFor="inputTitle">Title</label>
              <input
                type="text"
                className="form-control"
                id="inputTitle"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Col>
            <Col md={12}>
              <label htmlFor="inputDescription">Description</label>
              <textarea 
                className="form-control" 
                id="inputDescription" 
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                ></textarea>
            </Col>
          </Row>
          <Row className="mt-3">
          <Col>
              <label htmlFor="inputImages">Image</label>
              <input
                type="file"
                className="form-control"
                id="inputImages"
                onChange={(e) =>
                    setImage(e.target.files[0])
                }
                required
              />
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={addCityHandler} disabled={isError || isLoading}>
        {isLoading ? "Adding" : "Add"}
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
export default AddCityModal