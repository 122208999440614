import axios from "../../api/axios";

const API_URL = "api/contacts";

// get Contacts
const getAllContacts = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);
  // console.log(response);

  return response.data.data;
};

const deleteContact = async (contactId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${contactId}`, config);
  // console.log(response);
  if(response.status === 204){
    return {id: contactId}
  }
};

const contactService = {
  getAllContacts,
  deleteContact,
};

export default contactService;
