import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteContact } from "../../features/contacts/contactSlice";

const DeleteContactModal = ({onClose, onShow}) => {
  const { deletingId } = useSelector((state) => state.contact);
  const dispatch = useDispatch();

  const deleteHandler = () => {
    dispatch(deleteContact(deletingId));
    onClose();
    toast.success("Message deleted");
  };
  return (
    <Modal show={onShow} onHide={onClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Delete Message</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure to delete this message?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Close
      </Button>
      <Button variant="danger" onClick={deleteHandler}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
export default DeleteContactModal