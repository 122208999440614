import axios from "../../api/axios";

const API_URL = "api/groups";

// create city
const createCity = async (cityData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": 'multipart/form-data',
    },
  };

  const response = await axios.post(API_URL, cityData, config);
  // console.log(response);

  return response.data.data;
};

// get All cities
const getAllCities = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data.data;
};


const deleteCity = async (cityId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${cityId}`, config);
  // console.log(response)
  if(response.status === 204){
    return {id: cityId}
  }
  // return response.data;
};

const updateCity = async (cityId, cityData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/${cityId}`,
    cityData,
    config
  );
  // console.log(response);
  return response.data;
};

const cityService = {
  createCity,
  getAllCities,
  deleteCity,
  updateCity,
};

export default cityService;
