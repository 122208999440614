import Logo from "./common/Logo";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import logoIcon from "../assets/logo-Icon.png"
const Header = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    navigate('/login')
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand to={"/"}>
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Offcanvas id="basic-navbar-nav" placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel`}>
              <img style={{
                width: '50px',
                height: '50px'
              }} src={logoIcon} alt="ma travel and tourism logo"/>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
              {user && <Nav className="d-flex align-items-center justify-content-center justify-content-md-end flex-grow-1 pe-3">
                <NavLink className="nav-link" aria-current="page" to={"/"}>
                  Home
                </NavLink>
                <NavLink className="nav-link" to={"/services"}>
                  Services
                </NavLink>
                <NavLink className="nav-link" to={"/cities"}>
                  Cities
                </NavLink>
                <NavLink className="nav-link" to={"/Contacts"}>
                  Contacts
                </NavLink>
                <NavLink className="nav-link" to={"/bookings"}>
                  Bookings
                </NavLink>
                <NavLink className="nav-link" to={"/faqs"}>
                  Faqs
                </NavLink>
                <NavLink className="nav-link" to={"/banners"}>
                  Banners
                </NavLink>
                <NavLink className="nav-link" to={"/resetPassword"}>
                  Change Password
                </NavLink>
                <button
                  className="btn btn-primary mt-3 mt-lg-0 ms-0 ms-md-4"
                  onClick={logoutHandler}
                >
                  Logout
                </button>
              </Nav>}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};
export default Header;
