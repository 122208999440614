import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllcities, reset, setDeletingId, setEditingId } from "../features/cities/citySlice";
import DeleteCityModal from "../components/cities/DeleteCityModal";
import AddCityModal from "../components/cities/AddCityModal";
import EditCityModal from "../components/cities/EditCityModal";
import { toast } from "react-toastify";
import Spinner from "../components/common/Spinner";
import CityCard from "../components/CityCard/CityCard";

const Cities = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { cities, isError, isLoading, message } = useSelector(
    (state) => state.city
  );
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addModalCloseHandler = () => setIsAddModalOpen(false);
  const deleteModalCloseHandler = () => setIsDeleteModalOpen(false);
  const deleteModalShowHandler = (id) => {
    dispatch(setDeletingId(id));
    setIsDeleteModalOpen(true);
  };
  const addModalShowHandler = () => {
    setIsAddModalOpen(true);
  };

  const editModalCloseHandler = () => setIsEditModalOpen(false);
  const editModalShowHandler = (id) => {
    dispatch(setEditingId(id));
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    if (isError) {
      // console.log(message);
      toast.error(message);
    }

    if (!user) {
      navigate("/login");
    }

    if (user) {
      dispatch(getAllcities());
    }

    return () => {
      dispatch(reset());
    };
  }, [user, dispatch, message, isError, navigate]);

  if (isError) {
    // console.log(message);
    toast.error(message);
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Container>
      <div className="d-flex flex-column gap-4 px-2 py-3 px-md-3">
      <div className="pt-3 d-flex align-items-center justify-content-between flex-wrap">
          <h2>Cities</h2>
          {cities.length > 0 && <button className="btn btn-primary" onClick={addModalShowHandler}>Add City</button>}
        </div>
        <div className="px-0 py-3 px-md-3">
          <div className="row justify-content-center">
            <ul className="list grid col-auto my-3">
              {cities.map((city) => (
                <li key={city.id}>
                  <CityCard onEdit={editModalShowHandler} onDelete={deleteModalShowHandler} id={city.id} title={city.title} image={`${process.env.REACT_APP_API_BASE_URL}${city?.image}`} />
                </li>
              ))}
            </ul>
            {isDeleteModalOpen && (
            <DeleteCityModal
              onShow={isDeleteModalOpen}
              onClose={deleteModalCloseHandler}
            />
          )}
          </div>
          {
            cities.length === 0 && 
            <>
              <div className="text-center fs-2">No City found</div>
              <div className="d-flex align-items-center justify-content-center mt-3">
                <button
                onClick={addModalShowHandler} className="btn btn-primary">Add City</button>
              </div>
            </>
          }
        </div>
      </div>
      {isAddModalOpen && (
        <AddCityModal
          onShow={isAddModalOpen}
          onClose={addModalCloseHandler}
        />
      )}
      {isEditModalOpen && (
        <EditCityModal
          onShow={isEditModalOpen}
          onClose={editModalCloseHandler}
        />
      )}
    </Container>
  );
}
export default Cities