import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import serviceService from "./serviceService";

const initialState = {
  services: [],
  selectedService: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  error: null,
  editingId: null,
  deletingId: null,
};

// create
export const createService = createAsyncThunk(
  "services/create",
  async (serviceData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceService.createService(serviceData, token);
    } catch (error) {
      console.log(error);
      const message = "api error"
        // (error.response &&
        //   error.response.data &&
        //   error.response.data.message) ||
        // error.message ||
        // error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get all service
export const getAllServices = createAsyncThunk(
  "services/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceService.getAllServices(token);
    } catch (error) {
      console.log(error);
      const message = "api error"
        // (error.response &&
        //   error.response.data &&
        //   error.response.data.message) ||
        // error.message ||
        // error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get service
export const getService = createAsyncThunk(
  "services/get",
  async (serviceId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceService.getService(serviceId, token);
    } catch (error) {
      console.log(error);
      const message = "api error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete service
export const deleteService = createAsyncThunk(
  "services/delete",
  async (serviceId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceService.deleteService(serviceId, token);
    } catch (error) {
      const message = "api error"
        // (error.response &&
        //   error.response.data &&
        //   error.response.data.message) ||
        // error.message ||
        // error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteServiceImage = createAsyncThunk(
  "services/deleteImage",
  async (imageId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceService.deleteServiceImage(imageId, token);
    } catch (error) {
      const message = "api error"
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setEditingId: (state, action) => {
      state.editingId = action.payload;
    },

    setDeletingId: (state, action) => {
      state.deletingId = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(createService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.services.push(action.payload);
      })
      .addCase(createService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selectedService = action.payload;
      })
      .addCase(getService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.selectedService = {}
      })
      .addCase(getAllServices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.services = action.payload;
      })
      .addCase(getAllServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.services = state.services.filter(
          (service) => service.id !== action.payload.id
        );
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      
      .addCase(deleteServiceImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteServiceImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selectedService = {
          ...state.selectedService, 
          images: state.selectedService.images?.filter(image => image.id !== action.payload.id)}
      })
      .addCase(deleteServiceImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setEditingId, setDeletingId } = serviceSlice.actions;
export default serviceSlice.reducer;
