import axios from "../../api/axios";

const API_URL = "api/v1/";

// register user
// const register = async (userData) => {
//   const response = await axios.post(API_URL + "register", userData);

//   // console.log(response);
//   if (response.data) {
//     localStorage.setItem("user", JSON.stringify(response.data));
//   }

//   return response.data;
// };

// login user
const login = async (userData) => {
  const response = await axios.post(API_URL + "login", userData);
  

  console.log(response);
  if (response) {
    console.log(response.data);
    localStorage.setItem("user", JSON.stringify(response.data));
    return response.data;
    // return null;
  }

  // console.log(response);
};

const logout = async () => {
  localStorage.removeItem("user");
};

const authService = {
  // register,
  // verifyAccount,
  logout,
  login,
};

export default authService;
