import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../components/common/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllServices, reset, setDeletingId } from "../features/services/serviceSlice";
import ServiceCard from '../components/ServiceCard/ServiceCard';
import DeleteServiceModal from "../components/services/DeleteServiceModal";


const Services = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { services, isError, isLoading, message } = useSelector(
    (state) => state.service
  );
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteModalCloseHandler = () => setIsDeleteModalOpen(false);
  const deleteModalShowHandler = (id) => {
    dispatch(setDeletingId(id));
    setIsDeleteModalOpen(true);
  };


  useEffect(() => {
    if (isError) {
      // console.log(message);
      toast.error(message);
    }

    if (!user) {
      navigate("/login");
    }

    if (user) {
      dispatch(getAllServices());
    }

    return () => {
      dispatch(reset());
    };
  }, [user, dispatch, message, isError, navigate]);

  if (isError) {
    // console.log(message);
    toast.error(message);
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Container>
      <div className="d-flex flex-column gap-4 px-2 py-3 px-md-3">
      <div className="pt-3 d-flex align-items-center justify-content-between flex-wrap">
          <h2>Services</h2>
          {services.length > 0 && <Link className="btn btn-primary" to={"/services/add-new"}>Add Service</Link>}
        </div>
        <div className="px-0 py-3 px-md-3">
          <div className="row justify-content-center">
            <ul className="list grid col-auto my-3">
              {services.map((service) => (
                <li key={service.id}>
                  <ServiceCard onDelete={deleteModalShowHandler} id={service.id} title={service.title} image={`${process.env.REACT_APP_API_BASE_URL}${service?.images[0]?.image}`} />
                </li>
              ))}
            </ul>
            {isDeleteModalOpen && (
            <DeleteServiceModal
              onShow={isDeleteModalOpen}
              onClose={deleteModalCloseHandler}
            />
          )}
          </div>
          {
            services.length === 0 && 
            <>
              <div className="text-center fs-2">No Service found</div>
              <div className="d-flex align-items-center justify-content-center mt-3">
              <Link className="btn btn-primary" to={"/services/add-new"}>Add Service</Link>
              </div>
              
            </>
          
          }
        </div>
      </div>
    </Container>
  );
};
export default Services;
