import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const EditCityModal = ({onClose, onShow}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const {cities, editingId} = useSelector(state => state.city)


  useEffect(() => {
    if(!title || !description || !image){
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [title, description, image]);

  useEffect(() => {
    const { title,  description, image } = cities.filter(
      (city) => city.id === editingId
    )[0];
    setTitle(title);
    setDescription(description);
    // setImage(image);
    setCurrentImage(`${process.env.REACT_APP_API_BASE_URL}${image}`)
  }, [cities, editingId]);

  const imageUploadHandler = (e) => {
    const selectImage = e.target.files[0];
    setImage(e.target.files[0])
    if (selectImage) {
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const imageSrc = e.target.result;
        // console.log(imageSrc);
        setCurrentImage(imageSrc);
      };
  
      reader.readAsDataURL(selectImage);
    }
  }

  const updateCityHandler = async() => {  
    const formData = new FormData();
    setIsLoading(true);

    // Append title and description
    formData.append('title', title);
    formData.append('description', description);
    formData.append('_method', 'PUT');

    // Append the selected file, if available
    if (image) {
      formData.append('image', image, image.name);
    }
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
      },
    };
    try {
      const response = await axios.post(
        `api/groups/${editingId}`,
        formData,
        config
      );
      console.log(response);
      if (response.status === 200) {
        setIsLoading(false);
        window.location.reload(true);
        toast.success("City updated");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status !== 200) {
        toast.error(error.response.data.message);
      }
    }
    onClose();
  }


  return (
    <Modal show={onShow} onHide={onClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Edit City</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form className="col-12" >
        <Container>
          <Row className="align-items-end gap-3">
            <Col md={12}>
              <label htmlFor="inputTitle">Title</label>
              <input
                type="text"
                className="form-control"
                id="inputTitle"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Col>
            <Col md={12}>
              <label htmlFor="inputDescription">Description</label>
              <textarea 
                className="form-control" 
                id="inputDescription" 
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                ></textarea>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <img src={`${currentImage}`} alt="city images" />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <label htmlFor="inputImages">Images</label>
              <input
                type="file"
                className="form-control"
                id="inputImages"
                onChange={(e) => 
                  imageUploadHandler(e)
                }
                required
              />
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={updateCityHandler} disabled={isError || isLoading}>
        {isLoading ? "Updating" : "Update"}
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
export default EditCityModal