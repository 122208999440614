import axios from "../../api/axios";

const API_URL = "api/banners";

// create city
const createBanner = async (bannerData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": 'multipart/form-data',
    },
  };

  const response = await axios.post(API_URL, bannerData, config);
  // console.log(response);

  return response.data.data;
};

// get All cities
const getAllBanners = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data.data;
};


const deleteBanner = async (bannerId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${bannerId}`, config);
  // console.log(response)
  if(response.status === 204){
    return {id: bannerId}
  }
  // return response.data;
};

const updateBanner = async (bannerId, bannerData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/${bannerId}`,
    bannerData,
    config
  );
  // console.log(response);
  return response.data;
};

const bannerService = {
  createBanner,
  getAllBanners,
  deleteBanner,
  updateBanner,
};

export default bannerService;
